<template>
  <v-dialog
    v-model="dialogAtividadeColaborador"
    @click:outside="$emit('update:dialogAtividadeColaborador', false)"
    @keydown.esc="$emit('update:dialogAtividadeColaborador', false)"
    fullscreen
    scrollable
  >
    <v-card tile :loading="loading">
      <!--TITULO -->
      <v-card-title
        class=" align-center  accent text-h5 font-weight-bold pa-3 "
      >
        <v-icon color="primary" left>mdi-briefcase-edit-outline</v-icon>
        {{ atividade.titulo }}
        <v-spacer></v-spacer>
        <StopWatch
          v-if="atividade && atividade.id"
          :atividade_id="atividade.id"
        />
        <v-divider vertical class="mx-5"></v-divider>
        <!-- prioridade -->
        <v-icon
          :color="
            atividade.prioridade === 1
              ? 'prioridade_baixa'
              : atividade.prioridade === 2
              ? 'prioridade_normal'
              : atividade.prioridade === 3
              ? 'prioridade_urgente'
              : 'prioridade_emergencia'
          "
        >
          mdi-flag
        </v-icon>
        <v-divider class="mx-5" vertical></v-divider>
        <!-- data de entrega -->
        <v-sheet class="pa-2 text-body-1 accent" color="">
          {{ atividade.entrega | dateFormat("dd/MM/yyyy")
          }}<v-icon right>mdi-calendar</v-icon>
        </v-sheet>
        <!--botao de fechar -->
        <v-btn icon @click="$emit('update:dialogAtividadeColaborador', false)">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- conteudo -->
      <v-card-text
        style="height: calc(100vh - 69px); overflow:hidden;"
        class="pa-0"
      >
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row no-gutters>
            <!-- primeira coluna -->
            <v-col cols="12" md="8">
              <v-tabs v-model="tab">
                <v-tab>Informações</v-tab>
                <v-tab>Anexos</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-divider></v-divider>
                  <v-card-text
                    class=""
                    style="height: calc(100vh - 179px); overflow:auto;"
                  >
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-sheet class="text-caption">Cliente</v-sheet>
                        <v-sheet
                          class="px-3 py-1"
                          rounded="rounded"
                          color="#F5F5F5"
                          >{{ atividade.cliente_nome_fantasia }}</v-sheet
                        >
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-sheet class="text-caption">Serviço</v-sheet>
                        <v-sheet
                          class="px-3 py-1"
                          rounded="rounded"
                          color="#F5F5F5"
                          >{{ atividade.cliente_servico }}</v-sheet
                        >
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-sheet class="text-caption"
                          >Descrição dos serviços</v-sheet
                        >
                        <v-textarea
                          class="py-1"
                          rounded="rounded"
                          color="#F5F5F5"
                          outlined
                          v-model="atividade.descricao_servicos"
                          required
                          :rules="formRules"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-sheet class="text-caption"
                          >Informações pertinente</v-sheet
                        >
                        <v-textarea
                          class=" py-1"
                          rounded="rounded"
                          color="#F5F5F5"
                          outlined
                          v-model="atividade.pertinentes"
                          required
                          :rules="formRules"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <!-- anexos -->
                <v-tab-item>
                  <v-divider></v-divider>
                  <v-col cols="12" md="12">
                    <InternFileManager
                      origem="atividades"
                      :origem_id="atividade.id"
                      title="Anexos"
                      :smallComponent="true"
                      :disabled="
                        atividade.status === 7 || atividade.status === -1
                      "
                    />
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
              <v-divider></v-divider>
            </v-col>

            <!-- segunda coluna-->

            <v-col cols="12" md="4">
              <div class="" style="border-left: 1px solid #e0e0e0 !important;">
                <v-btn-toggle
                  class="pa-2 pb-1"
                  color="deep-purple accent-3"
                  v-model="text"
                  group
                >
                  <v-btn
                    value="all"
                    small
                    @click="selectedTipoComentario('all')"
                  >
                    <span class="hidden-sm-and-down">Todos</span>
                  </v-btn>
                  <v-btn
                    value="Histórico"
                    small
                    @click="selectedTipoComentario('Histórico')"
                  >
                    <span class="hidden-sm-and-down">Histórico</span>
                  </v-btn>
                  <v-btn
                    value="Comentário"
                    small
                    @click="selectedTipoComentario('Comentário')"
                    ><span class="hidden-sm-and-down">Comentário</span>
                  </v-btn>
                </v-btn-toggle>
              </div>

              <v-divider></v-divider>
              <v-card-text
                ref="container"
                style="border-left: 1px solid #e0e0e0 !important; height: calc(100vh - 265px);  overflow:auto; background-color: #F5F5F5 "
              >
                <AtividadesComentarios
                  v-if="atividade && atividade.id"
                  :atividade_id="atividade.id"
                  :comentario_add="comentarioAux"
                  :comentario_tipo="comentarioTipo"
                  :selected_tipo="selectedTipo"
                  :key="comentarioKey"
                  @last-scroll="scrollToEnd"
                />
              </v-card-text>
              <v-divider></v-divider>
              <v-card tile flat color="accent" class="px-3 py-2">
                <v-textarea
                  name="comentario-colaborador"
                  label="Escreva aqui seu comentário"
                  hint="comentário"
                  no-resize
                  rows="1"
                  v-model="comentario"
                  append-outer-icon="mdi-comment"
                  @keydown.enter="addComentario()"
                  @click:append-outer="addComentario()"
                >
                </v-textarea>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn
          class="mr-3"
          exact
          @click="$emit('update:dialogAtividadeColaborador', false)"
        >
          voltar
        </v-btn>
        <v-spacer></v-spacer>
        <div class="mr-1 text-button font-weight-bold">-></div>
        <v-menu
          :disabled="
            atividade.status < 0 ||
            atividade.status === 3 ||
            this.atividade.status === 5 ||
            this.atividade.status === 7
              ? true
              : false
          "
          offset-y
          rounded="rounded"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loadingButton"
              dark
              depressed
              :color="
                atividade.status == 0
                  ? 'grey'
                  : atividade.status == 1
                  ? 'blue-grey'
                  : atividade.status == 2
                  ? 'yellow darken-4'
                  : atividade.status == 3
                  ? 'green'
                  : atividade.status == 4
                  ? 'purple'
                  : atividade.status == 5
                  ? 'green darken-4'
                  : atividade.status == 6
                  ? 'deep-purple accent-4'
                  : atividade.status == -1
                  ? 'red'
                  : 'light-green'
              "
              v-bind="attrs"
              v-on="on"
            >
              {{ atividade.status | statusAtividade }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="statusChange(item)"
              v-for="(item, index) in status"
              :key="index"
            >
              <v-icon :color="item.cor" small left>mdi-square-rounded</v-icon>
              <v-list-item-action-text>{{ item.stat }}</v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn
          color="secondary"
          class=""
          @click="salvarAtividade"
          :disabled="
            atividade.status === -1 ||
              atividade.status === 7 ||
              desabilitarButtom
          "
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  putAtividadeCliente,
  fetchAtividade,
} from "@/api/geral/atividades_clientes.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapActions, mapState } from "vuex";

export default {
  name: "AtividadesColaborador",

  components: {
    StopWatch: () => import("@/components/stopwatch/StopWatch.vue"),
    AtividadesComentarios: () =>
      import("@/components/comentarios/AtividadesComentarios.vue"),
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
  },

  props: {
    dialogAtividadeColaborador: {
      type: Boolean,
      required: true,
    },
    item: {
      type: [Number, String],
    },
  },

  data() {
    return {
      selectedTipo: "",
      comentarioKey: 0,
      validForm: true,
      atividade: {},
      atividadeOriginal: {},
      text: "all",
      loading: false,
      loadingButton: false,
      comentario: null,
      comentarioTipo: 0,
      formRules: [inputRequired],
      tab: 0,
      comentarioAux: "",

      status: [
        { id: 0, stat: "A FAZER", cor: "grey" },
        { id: 1, stat: "EM ANDAMENTO", cor: "blue-grey" },
        { id: 2, stat: "EM ESPERA", cor: "yellow darken-4" },
        { id: 3, stat: "CONCLUIDO", cor: "green" },
      ],
    };
  },

  computed: {
    ...mapState("Stopwatch", {
      running: (state) => state.running,
    }),
    ...mapState("Usuario", {
      usuarioName: (state) => state.usuario.name,
    }),
    desabilitarButtom() {
      if (
        !this.validForm ||
        JSON.stringify(this.atividadeOriginal) ===
          JSON.stringify(this.atividade)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("Stopwatch", {
      STORE_check: "check",
      STORE_start: "start",
      STORE_stop: "stop",
      STORE_stopTimer: "stopTimer",
      STORE_getHistorico: "getHistorico",
    }),
    scrollToEnd() {
      let container = this.$refs.container;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    },
    statusChange(item) {
      if (this.atividade.status >= 0 && this.atividade.status <= 6) {
        this.loadingButton = true;
        let atividade = {};
        atividade.status = item.id;

        if (atividade.status === 1) {
          if (!this.running) {
            this.STORE_start();
          }
        } else if (atividade.status === 2) {
          if (this.running) {
            this.STORE_stop();
          }
        } else if (atividade.status === 3) {
          if (this.running) {
            this.STORE_stop();
          }
        }

        putAtividadeCliente(this.atividade.id, atividade).then((response) => {
          if (response.status === 201) {
            if (atividade.status === 0) {
              this.comentarioAux = `${this.usuarioName}, mudou status: A FAZER`;
              this.comentarioTipo = 0;
              this.comentarioKey += 1;
            } else if (atividade.status === 1) {
              this.comentarioAux = `${this.usuarioName}, mudou status: EM ANDAMENTO`;
              this.comentarioTipo = 0;
              this.comentarioKey += 1;
            } else if (atividade.status === 2) {
              this.comentarioAux = `${this.usuarioName}, mudou status: EM ESPERA`;
              this.comentarioTipo = 0;
              this.comentarioKey += 1;
            } else if (atividade.status === 3) {
              this.comentarioAux = `${this.usuarioName}, mudou status: CONCLUÍDO`;
              this.comentarioTipo = 0;
              this.comentarioKey += 1;
            }
            this.getAtividade();
            this.$emit("fetch-notificacoes");

            this.loadingButton = false;
          }
        });
      }
    },
    salvarAtividade() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let atividade = {};
        atividade.cliente_id = this.atividade.cliente_id;
        atividade.descricao_servicos = this.atividade.descricao_servicos;
        atividade.pertinentes = this.atividade.pertinentes;
        atividade.status = this.atividade.status;

        putAtividadeCliente(this.atividade.id, atividade).then((response) => {
          if (response.status === 201) {
            this.$toast.success("Atividade atualizado com sucesso!");
            this.getAtividade();
            this.$emit("fetch-notificacoes");

            this.loading = false;
          }
        });
      }
    },

    getAtividade() {
      this.loading = true;
      return fetchAtividade(this.item)
        .then((response) => {
          this.atividade = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addComentario() {
      this.comentarioAux = this.comentario;
      this.comentarioTipo = 1;
      this.comentarioKey += 1;
      this.comentario = null;
    },

    selectedTipoComentario(tipo) {
      this.comentarioTipo = null;
      this.comentarioAux = null;
      this.comentario = null;
      this.selectedTipo = tipo;
      this.comentarioKey += 1;
    },
  },

  async mounted() {
    await this.getAtividade();
    this.atividadeOriginal = { ...this.atividade };
    this.$store.dispatch("Stopwatch/setAtividade", this.atividade);
  },
};
</script>

<style></style>
